import React from 'react';

export function Redo({ width = 14, height = 14 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path d="M18.172 7H11a6 6 0 000 12h9v2h-9a8 8 0 010-16h7.172l-2.536-2.536L17.05 1.05 22 6l-4.95 4.95-1.414-1.415L18.172 7z"></path>
    </svg>
  );
}
