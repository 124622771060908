import React from 'react';

export function H5({ width = 14, height = 14 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path d="M22 8v2h-4.323l-.464 2.636A4.006 4.006 0 0122.25 16.5a4 4 0 01-7.846 1.103l1.923-.551a2 2 0 10.363-1.804l-1.81-.904L16 8h6zM4 4v7h7V4h2v16h-2v-7H4v7H2V4h2z"></path>
    </svg>
  );
}
