import React from 'react';

export function Paragraph({ width = 14, height = 14 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path d="M12 6v15h-2v-5a6 6 0 010-12h10v2h-3v15h-2V6h-3zm-2 0a4 4 0 100 8V6z"></path>
    </svg>
  );
}
