export function ItalicIcon(props: React.HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <rect width="24" height="24" rx="4"></rect>
      <path
        stroke={props.color || '#111C36'}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.318 7.063h-5.553M13.233 16.936H7.68M13.85 7.063l-3.702 9.87"
      ></path>
    </svg>
  );
}

/* eslint no-undef: 0 */ // --> OFF
export function BoldIcon(props: React.HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={props.color || '#111C36'}
        d="M7.948 17V6.09h4.368c.803 0 1.472.12 2.008.358s.94.568 1.21.99c.27.42.404.903.404 1.45 0 .426-.085.8-.256 1.123-.17.32-.404.583-.703.789a2.882 2.882 0 01-1.012.431v.107a2.49 2.49 0 012.056 1.262c.228.387.341.849.341 1.385 0 .579-.143 1.096-.431 1.55-.284.451-.705.808-1.262 1.07-.558.264-1.245.395-2.062.395h-4.66zm2.307-1.886h1.88c.643 0 1.111-.122 1.406-.367.295-.249.442-.579.442-.991 0-.302-.072-.568-.218-.799a1.483 1.483 0 00-.623-.543c-.267-.132-.585-.197-.954-.197h-1.933v2.897zm0-4.458h1.71c.316 0 .596-.055.841-.165.249-.114.444-.274.586-.48a1.25 1.25 0 00.218-.74c0-.394-.14-.712-.42-.954-.277-.241-.671-.362-1.183-.362h-1.752v2.7z"
      ></path>
    </svg>
  );
}

export function UnderlineIcon(props: React.HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke={props.color || '#111C36'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        d="M15.43 6v5.143a3.429 3.429 0 01-6.858 0V6M6.857 18h10.286"
      ></path>
    </svg>
  );
}

export function UnorderedListIcon(props: React.HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={props.color || '#111C36'}
        stroke="#fff"
        strokeWidth="0.4"
        d="M10.417 17.05a.55.55 0 110-1.1h7.833a.55.55 0 110 1.1h-7.833zm0-4.5a.55.55 0 110-1.1h7.833a.55.55 0 110 1.1h-7.833zm0-4.5a.55.55 0 110-1.1h7.833a.55.55 0 110 1.1h-7.833zM6.556 17.8c-.377 0-.693-.127-.96-.385A1.22 1.22 0 015.2 16.5c0-.357.13-.658.396-.915.267-.258.583-.385.96-.385.376 0 .692.127.96.385.266.257.395.558.395.915s-.129.658-.396.915a1.326 1.326 0 01-.96.385zm0-4.5c-.377 0-.693-.127-.96-.385A1.22 1.22 0 015.2 12c0-.357.13-.658.396-.915.267-.258.583-.385.96-.385.376 0 .692.127.96.385.266.257.395.558.395.915s-.129.658-.396.915a1.326 1.326 0 01-.96.385zm0-4.5c-.377 0-.693-.127-.96-.385A1.22 1.22 0 015.2 7.5c0-.357.13-.658.396-.915.267-.258.583-.385.96-.385.376 0 .692.127.96.385.266.257.395.558.395.915s-.129.658-.396.915a1.326 1.326 0 01-.96.385z"
      ></path>
    </svg>
  );
}

export function FontColorIcon(
  props: React.HTMLAttributes<HTMLOrSVGElement> & { chosenColor?: string }
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <g clipPath="url(#clip0_161_3202)">
        <path
          fill={props.color || '#111C36'}
          d="M8.979 14.5H7.348l3.664-10.182h1.775L16.45 14.5h-1.63l-2.88-8.332h-.079L8.98 14.5zm.273-3.987h5.29v1.292h-5.29v-1.292z"
        ></path>
        <path
          stroke={props?.chosenColor || '#111C36'}
          strokeWidth="3"
          d="M4 18L20 18"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_161_3202">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
